<template>
  <b-card
    no-body
    class="mb-1"
  >
    <b-card-header class="pb-1">
      <h4 class="card-title font-weight-bolder">
        {{ $t('employee.filters') }}
      </h4>
      <div class="d-flex align-items-center justify-content-end">
        <!-- Search -->
        <b-button
          variant="warning"
          class="mr-1"
          :disabled="emptyFilter || !canAccess('employee.searchEmployee')"
          @click="$emit('fetch-data')"
        >
          <span class="text-nowrap">
            <feather-icon
              class="cursor-pointer"
              icon="SearchIcon"
              size="16"
            />
            {{ $t('employee.search') }}
          </span>
        </b-button>

        <!-- Clear -->
        <b-button
          variant="danger"
          :disabled="emptyFilter || !canAccess('employee.searchEmployee')"
          @click="$emit('reset')"
        >
          <span class="text-nowrap">
            <feather-icon
              class="cursor-pointer"
              icon="XOctagonIcon"
              size="16"
            />
            {{ $t('employee.clear') }}
          </span>
        </b-button>
      </div>
    </b-card-header>

    <b-card-body class="py-0">
      <b-row class="d-flex justify-content-start mb-1">
        <!-- ANCHOR Type -->
        <b-col
          cols="12"
          sm="6"
          :md="canSearchEmployeeCode ? 3 : 4"
        >
          <b-form-group
            :label="$t('employee.type')"
            label-for="Type"
          >
            <v-select
              class="w-100"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="typeOfEmployeeOptionsFilter"
              label="label"
              :clearable="false"
              :value="typeFilter"
              :placeholder="$t('placeholderSelect')"
              :reduce="val => val.value"
              @input="(val) => $emit('update:typeFilter', [val])"
            >
              <template #option="data">
                <span>
                  {{ $te(data.label) ? $t(data.label) : data.label }}
                </span>
              </template>

              <template #selected-option="data">
                <span>
                  {{ $te(data.label) ? $t(data.label) : data.label }}
                </span>
              </template>

              <template #no-options>
                {{ $t('noOptions') }}
              </template>
            </v-select>
          </b-form-group>
        </b-col>

        <!-- ANCHOR: Created by -->
        <b-col
          cols="12"
          sm="6"
          :md="canSearchEmployeeCode ? 3 : 4"
        >
          <b-form-group
            :label="$t('createdBy')"
            label-for="created-by"
          >
            <v-select
              id="created-by-select"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="createdByOptions"
              label="firstName"
              searchable
              :clearable="false"
              :filterable="false"
              :value="createdByFilter"
              :loading="isLoading"
              :placeholder="$t('placeholderSelect')"
              :reduce="val => val"
              @open="handleOpenCreatedBy"
              @search="handleSearchCreatedBy"
              @input="(val) => $emit('update:createdByFilter', val)"
            >
              <template #selected-option="{firstName, lastName, type}">
                <div class="w-100 d-flex justify-content-between">
                  <span
                    class="font-weight-bold d-block text-nowrap"
                  >
                    {{ lastName }} {{ firstName }}
                  </span>
                  <b-badge
                    class="line-height-condensed mr-1"
                    :variant="resolveEmployeeTypeVariant(type)"
                  >
                    {{ $t(resolveEmployeeType(type, 'value', 'label')) }}
                  </b-badge>
                </div>
              </template>
              <template #spinner="{ loading }">
                <div
                  v-if="loading"
                  style="border-left-color: rgba(88, 151, 251, 0.71)"
                  class="vs__spinner"
                />
              </template>
              <template v-slot:option="{firstName, lastName,type}">
                <b-row>
                  <b-col
                    cols="12"
                    md="9"
                  >
                    <span
                      class="font-weight-bold d-block text-nowrap"
                    >
                      {{ lastName }} {{ firstName }}
                    </span>
                  </b-col>

                  <b-col
                    cols="12"
                    md="3"
                  >
                    <b-badge
                      :variant="resolveEmployeeTypeVariant(type)"
                    >
                      <span>
                        {{ $t(resolveEmployeeType(type, 'value', 'label')) }}
                      </span>
                    </b-badge>
                  </b-col>
                </b-row>
              </template>
            </v-select>
          </b-form-group>
        </b-col>

        <!-- ANCHOR: Status -->
        <b-col
          cols="12"
          sm="6"
          :md="canSearchEmployeeCode ? 3 : 4"
        >
          <b-form-group
            :label="$t('employee.status')"
            label-for="created-by"
          >
            <v-select
              class="w-100"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="statusOptions"
              label="label"
              :clearable="false"
              :value="statusFilter"
              :placeholder="$t('placeholderSelect')"
              :reduce="val => val.value"
              @input="(val) => $emit('update:statusFilter', val)"
            >
              <template #option="data">
                <span>
                  {{ $te(data.label) ? $t(data.label) : data.label }}
                </span>
              </template>

              <template #selected-option="data">
                <span>
                  {{ $te(data.label) ? $t(data.label) : data.label }}
                </span>
              </template>

              <template #no-options>
                {{ $t('noOptions') }}
              </template>
            </v-select>
          </b-form-group>
        </b-col>

        <!-- ANCHOR: EmployeeCode : nhap dung ma nhan vien de search -->
        <b-col
          v-if="canSearchEmployeeCode"
          cols="12"
          sm="6"
          md="3"
        >
          <b-form-group
            :label="$t('employee.employeeCode')"
            label-for="employee-code"
          >
            <b-form-input
              id="employee-code"
              :value="employeeCode"
              :reduce="val => val.value"
              :placeholder="$t('employee.placeholder.employeeCode')"
              :formatter="upperCaseFormatter"
              @keydown.enter="$emit('fetch-data')"
              @input="(val) => $emit('update:employeeCode', val)"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol, BFormInput,
  BButton, BFormGroup, BBadge,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { computed, ref } from '@vue/composition-api'
import { debounce } from 'lodash'

import { resolveEmployeeTypeVariant, resolveEmployeeType } from '@/constants/selectOptions'
import api from '@/api'
import store from '@/store'

import { upperCaseFormatter } from '@core/comp-functions/forms/formatter-input'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BButton,
    BFormGroup,
    BFormInput,
    BBadge,
    vSelect,
  },
  props: {
    genderFilter: {
      type: Array,
      default: null,
    },
    typeFilter: {
      type: Array,
      default: null,
    },
    createdByFilter: {
      type: [Object, null],
      default: () => {},
    },
    statusFilter: {
      type: Boolean,
      default: true,
    },
    employeeCode: {
      type: String,
      default: '',
    },
    typeOfEmployeeOptionsFilter: {
      type: Array,
      default: () => [],
    },
    genderOptions: {
      type: [Array, Object],
      default: () => [],
    },
    statusOptions: {
      type: Array,
      default: () => [],
    },
    emptyFilter: {
      type: Boolean,
      default: null,
    },
  },
  setup() {
    const createdByOptions = ref([])
    const isLoading = ref(false)
    const createdByOptionsDefault = ref([])

    // const meData = computed(() => store.getters['userStore/getMeData'])
    const isRoleF1 = computed(() => store.getters['userStore/getRoleMama'])

    const canSearchEmployeeCode = computed(() => isRoleF1.value) // mở cho F1
    // const isType = ['KTT', 'KTV'].includes(meData.value?.type) // HieuLM confirm chỉ KTT, KTV
    // return isType && isRoleF1.value

    const fetchEmployeesByFilterSearch = async (search = '') => {
      createdByOptions.value = []
      isLoading.value = true
      try {
        const data = await api.employee.fetchEmployeesByFilter({ types: ['ADM', 'OPE'], searchText: search })
        if (data && !search) {
          createdByOptionsDefault.value = data.items
        }
        createdByOptions.value = data?.items || []
      } catch (error) {
        console.error(error)
      } finally {
        isLoading.value = false
      }
    }

    function handleOpenCreatedBy() {
      if (createdByOptionsDefault.value.length === 0) {
        fetchEmployeesByFilterSearch()
      } else {
        createdByOptions.value = createdByOptionsDefault.value
      }
    }

    const handleSearchCreatedBy = debounce(search => {
      if (search) fetchEmployeesByFilterSearch(search)
    }, 500)

    return {
      createdByOptions,
      handleSearchCreatedBy,
      canSearchEmployeeCode,

      fetchEmployeesByFilterSearch,
      isLoading,
      handleOpenCreatedBy,

      resolveEmployeeTypeVariant,
      resolveEmployeeType,
      upperCaseFormatter,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.w-10{
  width: 10%;
}

.w-15{
  width: 15%;
}
</style>

<style lang="scss" scoped>
#created-by-select{
  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }
  ::v-deep .vs__selected {
    width: 100%;
  }
}
</style>
